import api from "../api";

export const uploadCommissionWithExcel = (data) => {
  return api.getApi().post(api.getBaseUrl() + "commissions/uploadExcel", data);
};

export const updateCommissionDetail = (data) => {
  return api
    .getApi()
    .put(
      api.getBaseUrl() +
        `commissions/${data.id}/detail/${data.commissionDetailId}`,
      data
    );
};

export const getErrorCommissions = (id) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + `payments/${id}/commissions/error-commissions`);
};

export const sendCommissionInvoiceMail = (id, mailBodyMessage) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + `commissions/${id}/send-commission-invoice-mail`, {
      mailBodyMessage: mailBodyMessage,
    });
};

export const deleteCommission = (id) => {
  return api.getApi().delete(api.getBaseUrl() + `commissions/${id}`);
};
