import { useMutation, useQuery } from "@tanstack/react-query";
import { getCommission } from "../../../data";
import { Link, useParams } from "react-router-dom";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { toast } from "@/components/ui/use-toast";
import { TrashIcon } from "lucide-react";
import { deleteCommission } from "@/data/requests/commission-requests";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthStore from "@/stores/AuthStore";

export function CommissionDetailScreen() {
  const { id, commissionId } = useParams();
  const [deleteInvoiceOpen, setDeleteInvoiceOpen] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery({
    queryKey: ["commissionDetail", commissionId],
    queryFn: async () => await getCommission(id, commissionId),
  });
  const { mutate: deleteInvoice } = useMutation({
    mutationFn: async () => await deleteCommission(commission.id),
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Commission deleted successfully",
      });
      // Navigate back after successful deletion
      navigate(-1);
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    },
  });
  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  if (error) {
    return (
      <AppLayout>
        <div>Error: {error.message}</div>
      </AppLayout>
    );
  }

  const commission = data.data;

  const handleDeleteInvoice = () => {
    deleteInvoice(commission.id);
    setDeleteInvoiceOpen(false);
  };

  return (
    <AppLayout title={`Commission Detail - ${commission.id}`}>
      <Card className="w-full ">
        <CardHeader>
          <Badge className="h-10">{commission.status}</Badge>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="email">Email</Label>
                <Input id="email" value={commission.email} readOnly />
              </div>
              <div>
                <Label htmlFor="invoiceId">Invoice ID</Label>
                <Input id="invoiceId" value={commission.invoiceId} readOnly />
              </div>
            </div>
            <div>
              <Label htmlFor="influencerName">Influencer Name</Label>
              <Input
                id="influencerName"
                value={commission.influencerName}
                readOnly
              />
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <Label htmlFor="finalAmount">Final Amount</Label>
                <Input
                  id="finalAmount"
                  value={commission.finalAmount}
                  readOnly
                />
              </div>
            </div>
            <div>
              <Label htmlFor="country">Country</Label>
              <Input id="country" value={commission.country} readOnly />
            </div>
            <div>
              <Label htmlFor="createdAt">Created At</Label>
              <Input
                id="createdAt"
                value={new Date(commission.createdAt).toLocaleString()}
                readOnly
              />
            </div>
            <div>
              <Label htmlFor="updatedAt">Updated At</Label>
              <Input
                id="updatedAt"
                value={new Date(commission.updatedAt).toLocaleString()}
                readOnly
              />
            </div>
            <div>
              <Button asChild>
                <Link to={`/invoice/${commission.uploadPath}`}>
                  Upload Invoice Preview
                </Link>
              </Button>
              {AuthStore.isHavePermission("PAYMENT_DELETE") && (
                <Button variant="destructive" asChild>
                  <Link onClick={() => setDeleteInvoiceOpen(true)}>
                    Delete Invoice
                  </Link>
                </Button>
              )}
            </div>
          </div>
        </CardContent>
        <AlertDialog open={deleteInvoiceOpen}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete the
                commission.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setDeleteInvoiceOpen(false)}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={() => handleDeleteInvoice(commission.id)}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Card>
    </AppLayout>
  );
}
